<template>
    <p v-html="title"></p>
    <!--  <p v-html="sldHorLine"></p>-->
    <el-form :model="settingForm" label-width="80px">
        <el-table :show-header="false" :data="tableData" border style="width: 100%">
            <el-table-column prop="date" min-width="200" align="right" label="新消息声音提醒">
            </el-table-column>
            <el-table-column align="left" min-width="700">
                <template #default="scope">
                    <template v-if="scope.row.type == 'text'">
                        <el-form-item label-width="0">
                            <el-switch v-model="settingForm.voice" @change="switchVoice"></el-switch>
                        </el-form-item>
                    </template>
                    <template v-if="scope.row.type == 'btn'">
                        <el-button type="primary" size="small" @click="onSubmit">保存</el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
    </el-form>
</template>
<script>
    import { getCurrentInstance, reactive, onMounted } from 'vue'
    import { ElMessage } from 'element-plus'
    import { useStore } from "vuex";

    export default {
        name: "Setting",
        components: {},
        setup() {
            const { proxy } = getCurrentInstance();
            const store = useStore();
            const title = proxy.$sldLlineRtextAddGoodsAddMargin('#0563FF', '聊天设置', 0, 0, 15);
            const sldHorLine = proxy.$getSldHorLine(1);
            const settingForm = reactive({ voice: false });//表单数据

            //获取消息提示设置，在用户登录页面写完时将此方法移至登录页面
            const getSetting = () => {
                proxy.$post('v3/helpdesk/seller/sound/setting/isOpen').then(res => {
                    settingForm.voice = res.data.isOpen ? true : false
                })
            }

            //开关方法
            const switchVoice = (e) => {
                settingForm.voice = e;
            }

            //保存数据
            const onSubmit = () => {
                let params = {
                    isOpen: settingForm.voice ? 1 : 0
                }
                proxy.$post('v3/helpdesk/seller/sound/setting/update', params).then(res => {
                    ElMessage.success(res.msg)
                    store.commit("updateMsgVoiceSetting",  settingForm.voice);
                })
            }

            //临时方法---获取token
            const setToken = () => {
                localStorage.setItem('refresh_token', 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1LCJ1c2VyX25hbWUiOiJzbG9kb24wMyIsInNjb3BlIjpbImFsbCJdLCJ2ZW5kb3Jfcm9sZV9pZCI6MiwiYXRpIjoiYTA0YTk1ZmEtYTI5MS00NGZlLWE1NWMtYjdjYjA1NmJkZTRjIiwiZXhwIjoxNjE2NDY5ODA1LCJ1dWlkIjoiYmNmOGI3MjMtNzQwZS00MWUyLTgwZTEtMmNjZjdlMzEwYmNmIiwianRpIjoiZmM0ZWJlMTMtMWRlYS00MGE1LWFjMjUtMTU4OWQ2ZmU0MTk1Iiwid2ViSWRlbnRpZnkiOiJzZWxsZXIiLCJjbGllbnRfaWQiOiJzZWxsZXIifQ.b3n8XvGWnMJ5NTf6Y-QdW4NWCokwrW3vnveSzLHKO_C_T8k3pdxlisZN2R7Ir9_3r2i_V7VGC4wvIXKQmzZB-6Pt6nsWtQWpoFO2hUzdJjhoLmVAiJpb1vHXBrmjrJHm1nSYiQ1H8wGx6M1W5SkIaHklWjNs9gjq4dlC2c0uBUU')
                localStorage.setItem('access_token', 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1LCJ1c2VyX25hbWUiOiJzbG9kb24wMyIsInNjb3BlIjpbImFsbCJdLCJ2ZW5kb3Jfcm9sZV9pZCI6MiwiZXhwIjoxNjE1MTc3NDA1LCJ1dWlkIjoiYmNmOGI3MjMtNzQwZS00MWUyLTgwZTEtMmNjZjdlMzEwYmNmIiwianRpIjoiYTA0YTk1ZmEtYTI5MS00NGZlLWE1NWMtYjdjYjA1NmJkZTRjIiwid2ViSWRlbnRpZnkiOiJzZWxsZXIiLCJjbGllbnRfaWQiOiJzZWxsZXIifQ.Ul3tJ6vhqGTKpKMKIP310RKO5qN6FtEx02DX5dqYuzVN-Z6r-1NHjPWvKBKENNkh-O-j4p1MdscCGXlUevxsSQ6WB5y8vNY0bs2542Xe1nwRy5Hj0cFSeIH3oIMdl3_hQ5G4K5Xk3BvyyKxWKbaaomYC0a2vSNM044n505j6JWI')
            }

            //临时方法---请求token
            const getLogin = () => {
                proxy.$post("/v3/sellerLogin/oauth/token").then(res => {
                    console.log(res.data)
                })
            }

            onMounted(() => {
                setToken()
                getLogin()
                getSetting()
            })



            return {
                title,
                sldHorLine,
                settingForm,
                tableData: [{
                    date: '新消息声音提醒',
                    name: 222,
                    type: 'text'
                }, {
                    date: '',
                    name: 222,
                    type: 'btn'
                },],
                onSubmit,
                switchVoice,
                getSetting
            };
        }
    };
</script>
<style lang="scss" scoped>
    .el-form-item {
        margin-bottom: 0;
    }
</style>
